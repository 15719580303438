import React, {useContext} from "react";
import {LazyLoadImage} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import {infoModalIdContext} from "./infoModalIdProvider";

export default function AboutUs(props) {
    const {setActiveInformation} = useContext(infoModalIdContext);
    const [innerWidth, setInnerWidth] = React.useState(window.innerWidth);
    const [currentLanguage, setCurrentLanguage] = React.useState(
        props.aboutusData.currentLanguage
    );

    React.useEffect(() => {
        setCurrentLanguage(props.aboutusData.currentLanguage);
    }, [props]);

    React.useEffect(() => {
        window.addEventListener("resize", () => {
            setInnerWidth(window.innerWidth);
        });
        return () => {
            window.removeEventListener("resize", () => {
            });
        };
    }, [innerWidth]);

    return (
        <section className="container-fluid about-us" style={{marginTop: "32px"}}>
            <h1
                className={
                    currentLanguage === "fa"
                        ? "aboutUs-title text-right"
                        : "aboutUs-title aboutUs-title-en text-left"
                }
            >
                {props.aboutusData.aboutusData[0]}
            </h1>
            <div className="container-fluid p-0 row justify-content-center">
                {innerWidth <= 576 ? (
                    <div className="col-4 dscrp-img-container">
                        <LazyLoadImage
                            alt="..."
                            className="dscrp-img"
                            src={require("../images/about us/about2-small.png")}
                            effect="blur"
                            wrapperProps={{
                                style: {transitionDelay: "1s"},
                            }}
                        />
                    </div>
                ) : (
                    <div className="col-6  col-xl-5 dscrp-img-container">

                        <LazyLoadImage
                            alt="..."
                            className="dscrp-img"
                            src={require("../images/about us/about2.webp")}
                            effect="blur"
                            wrapperProps={{
                                style: {transitionDelay: "1s"},
                            }}
                        />
                    </div>
                )}

                <div
                    className={
                        innerWidth <= 576
                            ? "col-8  col-xl-7 dscrp position-relative"
                            : "col-6  col-xl-7 dscrp position-relative"
                    }
                >
                    <div className="dscrp-text-container ">
                        <p
                            style={currentLanguage === 'fa' ? {direction: 'rtl'} : {direction: "ltr"}}
                            className={`dscrp-text position-relative `}
                        >
                            {props.aboutusData.aboutusData[1]}
                        </p>

                        <div className={`container-fluid dscrp-cover ${currentLanguage === 'fa' ? "fa" : "en"}`}>
                            <button
                                onClick={() => {
                                    setActiveInformation('about')
                                }}
                                data-toggle="modal"
                                data-target="#moreInformationModal"
                                className="btn "
                            >
                                {currentLanguage === 'fa' ? "بیشتر" : "more"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
