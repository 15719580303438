import {createContext, useState} from "react";


export const infoModalIdContext = createContext(null);

export default function InfoModalIdProvider(props) {

    const [serviceId, setServiceId] = useState("service1");
    const [sliderId, setSliderId] = useState(null);

    // about,service,slider
    const [activeInformation, setActiveInformation] = useState(null);


    const value = {
        serviceId,
        setServiceId,
        setActiveInformation,
        activeInformation,
        sliderId,
        setSliderId
    }


    return (
        <infoModalIdContext.Provider value={value}>
            {props.children}
        </infoModalIdContext.Provider>
    )
}