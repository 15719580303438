import React from "react";
import location from '../images/icons/location_grey.svg'
import phone from '../images/icons/phone_grey.svg'
import mobile from '../images/icons/cell_phone_grey.svg'


export default function ContactModal({modalData, currentLanguage}) {
    return (
        <>
            <div
                className="modal fade"
                id="contactModal"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered ">
                    <div className="modal-content">
                        <div className="modal-header p-0">
                            <img
                                className="img-fluid modal-img"
                                src={require("../../src/images/ian-taylor-jOqJbvo1P9g-unsplash.webp")}
                                alt="modal-img"
                            />
                        </div>
                        <div className="modal-body pb-0">
                            <h1 className="text-center"> {modalData[0]} </h1>
                        </div>
                        <div className="modal-footer d-flex flex-column align-items-end">
                            <div
                                className={`container-fluid  modal-footer-content ${
                                    currentLanguage === "fa" ? "fa" : "en"
                                }`}
                            >
                                <p style={{fontSize: "20px"}}> {modalData[1]} </p>
                                <p className="text-muted ">
                                    keredHolding@gmail.com
                                    <i className="fa fa-envelope mx-2" aria-hidden="true"></i>
                                </p>
                                <p className={currentLanguage === 'fa' ? "phone-number fa" : "phone-number en"}>
                                    <span>+98 (11) 44241662</span>
                                    <img className='mx-2' src={phone} alt="phone"/>
                                </p>
                                <p className={currentLanguage === 'fa' ? "mobile-number fa" : "mobile-number en"}>
                                    <span> +989113118955</span>
                                    <img src={mobile} alt="phone"/>
                                </p>
                                <p className={currentLanguage === 'fa' ? 'address fa' : "address en"}>
                                    <span style={{whiteSpace:'normal'}}>
                                        {modalData[2]}
                                    </span>
                                    <img src={location} alt="location"/>
                                </p>
                                <hr/>
                                <div className="container-fluid d-flex justify-content-center">
                                    <button
                                        type="button"
                                        className="btn btn-secondary modal-close-btn"
                                        data-dismiss="modal"
                                    >
                                        {modalData[3]}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}