import React, {useContext} from "react";
import {LazyLoadImage} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import {infoModalIdContext} from "./infoModalIdProvider";

export default function Services({servicesData, currentLang}) {

    const {setServiceId, setActiveInformation} = useContext(infoModalIdContext);

    const [innerWidth, setInnerWidth] = React.useState(window.innerWidth);
    const [currentLanguage, setCurrentLanguage] = React.useState(
        servicesData.currentLang
    );
    const [s4_5TitleClass, setS4_5TitleClass] = React.useState();

    React.useEffect(() => {
        setCurrentLanguage(currentLang);
    }, [currentLang]);

    React.useEffect(() => {
        if (currentLanguage === "fa") {
            setS4_5TitleClass("s4_5titleFa");
        } else {
            setS4_5TitleClass("s4_5titleEn");
        }
    }, [currentLanguage]);

    React.useEffect(() => {
        window.addEventListener("resize", () => {
            setInnerWidth(window.innerWidth);
        });
        return () => {
            window.removeEventListener("resize", () => {
            });
        };
    }, [innerWidth]);

    // noinspection LanguageDetectionInspection
    return (
        <section className="container-fluid services p-0">
            <h1
                id="service"
                className={
                    currentLanguage === "fa" ? "service-title fa " : "service-title en "
                }
            >
                {servicesData.title}
            </h1>
            {innerWidth < 1250 && (
                <hr
                    className={
                        currentLanguage === "fa"
                            ? "service-title-hr"
                            : "service-title-hr en"
                    }
                />
            )}
            {/* ============ service1 ============= */}
            {innerWidth >= 1250 ? (
                <div
                    id='service1'
                    style={{position: "relative", height: "372px"}}
                    className="container-fluid  service1 "
                >
                    <LazyLoadImage
                        alt="..."
                        className="service1-img"
                        src={require("../images/services/s1.webp")}
                        effect="blur"
                        wrapperProps={{
                            style: {transitionDelay: "1s"},
                        }}
                    />

                    <div className="d-flex flex-column text-right">
                        <div className="w-100">
                            <h2
                                className={
                                    currentLanguage === "fa"
                                        ? "service1-title"
                                        : "service1-title text-left p-3"
                                }
                            >
                                {servicesData.service1.title}
                            </h2>
                        </div>
                        <div className="w-100">
                            <div
                                className={
                                    currentLanguage === "fa"
                                        ? "service1-dscr-container"
                                        : "service1-dscr-container en"
                                }
                            >
                                <p className={`dscrp-text`}>
                                    {servicesData.service1.description_summary}
                                </p>
                                <ul
                                    className={
                                        currentLanguage === "fa" ? "dscrp-list" : "dscrp-list en"
                                    }
                                >
                                    <li className="dscrp-list-item">
                                        {servicesData.service1.services[0]}
                                    </li>
                                    <li className="dscrp-list-item">
                                        {servicesData.service1.services[1]}
                                    </li>
                                    <li className="dscrp-list-item">
                                        {servicesData.service1.services[2]}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="w-100">
                            <a
                                onClick={() => {
                                    setActiveInformation('service')
                                    setServiceId("service1")
                                }}
                                href="#"
                                data-toggle="modal"
                                data-target="#moreInformationModal"
                                className={`btn more-btn d-flex justify-content-center align-items-center
                                ${currentLanguage === "fa" ? "more-btn-fa" : "more-btn-en"}`}
                            >
                                <span> {servicesData.service1.more_btn}</span>
                                <i className="fa mx-1 fa-arrow-left" aria-hidden="true"></i>
                            </a>
                        </div>
                    </div>
                </div>
            ) : (
                <div
                    id='service1'
                    style={{position: "relative", marginTop: "60px"}}
                    className="container-fluid  service1-small"
                >
                    <h1
                        className={
                            currentLanguage === "fa"
                                ? "service1-small-header text-right"
                                : "service1-small-header text-left"
                        }
                    >
                        {servicesData.service1.title}
                    </h1>
                    <div className="row justify-content-center">
                        <div style={innerWidth < 1250 ? {paddingLeft: '0'} : null}
                             className="col-6 d-flex flex-column ">
                            <LazyLoadImage
                                alt="..."
                                className="w-100 h-100"
                                src={require("../images/services/s1-medium.webp")}
                                effect="blur"
                                wrapperProps={{
                                    style: {transitionDelay: "1s"},
                                }}
                            />
                            <a
                                onClick={() => {
                                    setActiveInformation('service')
                                    setServiceId("service1")
                                }}
                                href="#"
                                data-toggle="modal"
                                data-target="#moreInformationModal"
                                className={`btn  more-btn   ${currentLanguage === "fa" ? "more-btn-fa" : "more-btn-en"}`}
                            >
                                <span>
                                     {servicesData.service1.more_btn}
                                </span>
                                <i
                                    className="fa mx-1 fa-arrow-left"
                                    aria-hidden="true"
                                ></i>
                            </a>
                        </div>
                        <div className="col-6">
                            <div
                                style={innerWidth < 450 ? {overflow: 'auto'} : null}
                                className={
                                    currentLanguage === "fa"
                                        ? "dscrp-container"
                                        : "dscrp-container en"
                                }
                            >
                                <p className={`dscrp ${innerWidth < 500 ? "d-none" : "d-block"}`}>
                                    {servicesData.service1.description_summary}
                                </p>
                                <ul className={`d-flex flex-column ${currentLanguage === 'fa' ? "pl-0 pr-3" : "pr-0 pl-3"}`}>
                                    <li> {servicesData.service1.services[0]}</li>
                                    <li> {servicesData.service1.services[1]}</li>
                                    <li> {servicesData.service1.services[2]}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {/* ============ service2 ============= */}
            {innerWidth >= 1250 ? (
                <div
                    id='service2'
                    className="container-fluid  service2"
                    style={{position: "relative", height: "480px", marginTop: "48px"}}
                >
                    <div className="w-100">
                        <h2 className="service2-title d-flex justify-content-center align-items-center">
                            {servicesData.service2.title}
                        </h2>
                    </div>
                    <img
                        src={require("../images/services/frame1.png")}
                        className="service2-frame"
                    />
                    <div style={{position: "relative"}}>
                        <div
                            className={
                                currentLanguage === "fa"
                                    ? "service2-dscr1-container"
                                    : "service2-dscr1-container en"
                            }
                        >
                            <p className="dscrp-header">
                                1- {servicesData.service2.services[0]}
                            </p>
                            <p className="dscrp-text">
                                {servicesData.service2.description_summary[0]}
                            </p>

                        </div>
                        <div
                            className={
                                currentLanguage === "fa"
                                    ? "service2-dscr2-container"
                                    : "service2-dscr2-container en"
                            }
                        >
                            <p className="dscrp-header">
                                2- {servicesData.service2.services[1]}
                            </p>
                            <p className="dscrp-text">
                                {servicesData.service2.description_summary[1]}
                            </p>

                        </div>
                    </div>

                    <div className="w-100">
                        <a
                            onClick={() => {
                                setActiveInformation('service')
                                setServiceId("service2")
                            }}
                            href="#"
                            data-toggle="modal"
                            data-target="#moreInformationModal"
                            style={{zIndex: "1"}}
                            className={`btn more-btn d-flex justify-content-center align-items-center
                             ${currentLanguage === "fa" ? "more-btn-fa" : "more-btn-en"}`}
                        >
                            <span>{servicesData.service2.more_btn}</span>
                            <i className="fa mx-1 fa-arrow-left" aria-hidden="true"></i>
                        </a>
                    </div>
                    <LazyLoadImage
                        alt="..."
                        className="service2-img"
                        src={require("../images/services/s2.webp")}
                        effect="blur"
                        wrapperProps={{
                            style: {transitionDelay: "1s"},
                        }}
                    />
                </div>
            ) : (
                <div
                    id='service2'
                    style={{position: "relative", marginTop: "48px"}}
                    className="container-fluid  service2-small"
                >
                    <h1
                        className={
                            currentLanguage === "fa"
                                ? "service2-small-header text-right"
                                : "service2-small-header text-left"
                        }
                    >
                        {servicesData.service2.title}
                    </h1>
                    <div className="row justify-content-center">
                        <div className="col-6 pt-0  dscrp-container text-right">
                            <div
                                style={{height: "90%"}}
                                className={currentLanguage === "fa" ? "row" : "row en"}
                            >
                                <div className="col-12 p-0 pb-2">
                                    <div
                                        style={innerWidth < 450 ? {overflow: 'auto'} : null}
                                        className="subservice2-1">
                                        <p className="dscrp-header p-0">
                                            1. {servicesData.service2.services[0]}
                                        </p>
                                        <p className={`dscrp-text ${innerWidth < 460 ? "d-none" : "d-block"}`}>
                                            {servicesData.service2.description_summary[0]}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12 p-0  py-md-2">
                                    <div
                                        style={innerWidth < 450 ? {overflow: 'auto'} : null}
                                        className="subservice2-2 ">
                                        <p className="dscrp-header p-0">
                                            2.{servicesData.service2.services[1]}
                                        </p>
                                        <p className={`dscrp-text ${innerWidth < 460 ? "d-none" : "d-block"}`}>
                                            {servicesData.service2.description_summary[1]}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-6 d-flex flex-column text-right overflow-hidden">
                            <LazyLoadImage
                                alt="..."
                                className="w-100 h-100"
                                src={require("../images/services/s2-medium.webp")}
                                effect="blur"
                                wrapperProps={{
                                    style: {transitionDelay: "1s"},
                                }}
                            />
                            <a
                                onClick={() => {
                                    setActiveInformation('service')
                                    setServiceId("service2")
                                }}
                                href="#"
                                data-toggle="modal"
                                data-target="#moreInformationModal"
                                className={`btn  more-btn  ${currentLanguage === "fa" ? "more-btn-fa" : "more-btn-en"}`}
                            >
                                <span>
                                    {servicesData.service2.more_btn}
                                </span>
                                <i
                                    className="fa mx-1 fa-arrow-left"
                                    aria-hidden="true"
                                ></i>
                            </a>
                        </div>
                    </div>
                </div>
            )}
            {/* ============ service3 ============= */}
            {innerWidth >= 1250 ? (
                <div
                    id='service3'
                    style={{position: "relative", height: "372px", marginTop: "48px"}}
                    className="container-fluid  service3"
                >
                    <LazyLoadImage
                        alt="..."
                        className="service3-img"
                        src={require("../images/services/s3.webp")}
                        effect="blur"
                        wrapperProps={{
                            style: {transitionDelay: "1s"},
                        }}
                    />
                    <div className="d-flex flex-column text-right">
                        <div className="w-100">
                            <h2
                                className={
                                    currentLanguage === "fa"
                                        ? "service3-title "
                                        : "service3-title text-left p-3"
                                }
                            >
                                {servicesData.service3.title}
                            </h2>
                        </div>
                        <div className="w-100">
                            <div
                                className={
                                    currentLanguage === "fa"
                                        ? "service3-dscr-container"
                                        : "service3-dscr-container en"
                                }
                            >
                                <p className="dscrp-text">
                                    {servicesData.service3.description_summary}
                                </p>
                                <ul className="dscrp-list">
                                    <li className="dscrp-list-item">
                                        {servicesData.service3.services[0]}
                                    </li>
                                    <li className="dscrp-list-item">
                                        {servicesData.service3.services[1]}
                                    </li>
                                    <li className="dscrp-list-item">
                                        {servicesData.service3.services[2]}
                                    </li>
                                    <li className="dscrp-list-item">
                                        {servicesData.service3.services[3]}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="w-100">
                            <a
                                onClick={() => {
                                    setActiveInformation('service')
                                    setServiceId("service3")
                                }}
                                href="#"
                                data-toggle="modal"
                                data-target="#moreInformationModal"
                                className={`btn more-btn d-flex justify-content-center align-items-center
                                 ${currentLanguage === "fa" ? "more-btn-fa" : "more-btn-en "}`}
                            >
                                <span>{servicesData.service3.more_btn}</span>
                                <i className="fa mx-1 fa-arrow-left" aria-hidden="true"></i>
                            </a>
                        </div>
                    </div>
                </div>
            ) : (
                <div
                    id='service3'
                    style={{position: "relative", marginTop: "48px"}}
                    className="container-fluid  service3-small"
                >
                    <h1
                        className={
                            currentLanguage === "fa"
                                ? "service3-small-header text-right"
                                : "service3-small-header text-left"
                        }
                    >
                        {servicesData.service3.title}
                    </h1>
                    <div className="row justify-content-center">
                        <div
                            style={innerWidth < 1250 ? {paddingLeft: '0'} : null}
                            className="col-6 d-flex flex-column">
                            <LazyLoadImage
                                alt="..."
                                className="w-100 h-100"
                                src={require("../images/services/s3-medium.webp")}
                                effect="blur"
                                wrapperProps={{
                                    style: {transitionDelay: "1s"},
                                }}
                            />
                            <a
                                onClick={() => {
                                    setActiveInformation('service')
                                    setServiceId("service3")
                                }}
                                href="#"
                                data-toggle="modal"
                                data-target="#moreInformationModal"
                                className={`btn  more-btn  ${currentLanguage === "fa" ? "more-btn-fa" : "more-btn-en"}`}
                            >
                                <span>
                                    {servicesData.service3.more_btn}
                                </span>
                                <i
                                    className="fa mx-1 fa-arrow-left"
                                    aria-hidden="true"
                                ></i>
                            </a>
                        </div>
                        <div className="col-6 text-right ">
                            <div
                                style={innerWidth < 450 ? {overflow: 'auto'} : null}
                                className={
                                    currentLanguage === "fa"
                                        ? "dscrp-container"
                                        : "dscrp-container en"
                                }
                            >
                                <p className={`dscrp ${innerWidth < 500 ? "d-none" : "d-block"}`}>
                                    {servicesData.service3.description_summary}
                                </p>
                                <ul className={`d-flex flex-column ${currentLanguage === 'fa' ? "pl-0 pr-3" : "pr-0 pl-3"}`}>
                                    <li> {servicesData.service3.services[0]} </li>
                                    <li> {servicesData.service3.services[1]} </li>
                                    <li> {servicesData.service3.services[2]} </li>
                                    <li> {servicesData.service3.services[3]} </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {/*/!* ============ service4 ============= *!/*/}
            {innerWidth >= 1250 ? (
                <div
                    id='service4'
                    className="container-fluid  service4"
                    style={{position: "relative", height: "960px", marginTop: "48px"}}
                >
                    <h2 className="service4-title ">
                        <span className={s4_5TitleClass}>
                          <span>{servicesData.service4.title[0]}</span>
                            &nbsp;
                            <span>{servicesData.service4.title[1]}</span>
                        </span>
                    </h2>
                    <img
                        src={require("../images/services/frame2.png")}
                        className="service4-frame"
                    />
                    <img
                        className="frame4"
                        src={require("../images/services/frame4.png")}
                    />
                    <div
                        className={
                            currentLanguage === "fa" ? "w-100" : "w-100 dscrp-cont-en"
                        }
                    >
                        <div className="service4-dscr1-container ">
                            <p className="dscrp-header">
                                1- {servicesData.service4.services[0]}
                            </p>
                        </div>
                        <div className={`service4-dscr2-container ${currentLanguage === 'fa' ? "fa" : "en"}`}>
                            <p className="dscrp-header">
                                2- {servicesData.service4.services[1]}
                            </p>
                        </div>
                        <div className={`service4-dscr3-container ${currentLanguage === 'fa' ? "fa" : 'en'}`}>
                            <p className="dscrp-header">
                                3- {servicesData.service4.services[2]}
                            </p>
                        </div>
                        <div className={`service4-dscr4-container ${currentLanguage === 'fa' ? "fa" : "en"}`}>
                            <p className="dscrp-header">
                                4- {servicesData.service4.services[3]}
                            </p>
                        </div>
                    </div>
                    <div className="w-100">
                        <a
                            onClick={() => {
                                setActiveInformation('service')
                                setServiceId("service4")
                            }}
                            href="#"
                            data-toggle="modal"
                            data-target="#moreInformationModal"
                            className={`btn more-btn d-flex justify-content-center align-items-center
                             ${currentLanguage === "fa" ? "more-btn-fa" : "more-btn-en"}`}
                        >
                            <span>{servicesData.service4.more_btn}</span>
                            <i className="fa mx-1 fa-arrow-left" aria-hidden="true"></i>
                        </a>
                    </div>
                    <LazyLoadImage
                        alt="..."
                        className="service4-img"
                        src={require("../images/services/s4.webp")}
                        effect="blur"
                        wrapperProps={{
                            style: {transitionDelay: "1s"},
                        }}
                    />
                </div>
            ) : (
                <div
                    id='service4'
                    style={{position: "relative", marginTop: "48px"}}
                    className="container-fluid  service4-small"
                >
                    <h1
                        className={
                            currentLanguage === "fa"
                                ? "service4-small-header text-right"
                                : "service4-small-header text-left"
                        }
                    >
                        {servicesData.service4.title[0]}&nbsp;
                        {servicesData.service4.title[1]}
                    </h1>
                    <div className="row justify-content-center">
                        <div className="col-6 pt-0  dscrp-container text-right overflow-hidden">
                            <div
                                style={{height: "95%"}}
                                className={currentLanguage === "fa" ? "row" : "row en"}
                            >
                                <div className="col-12 p-0 pb-2">
                                    <div className="subservice4-1">
                                        <p className="dscrp-header p-0">
                                            1.{servicesData.service4.services[0]}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12 p-0 pb-2 py-md-2">
                                    <div className="subservice4-2 ">
                                        <p className="dscrp-header p-0">
                                            2.{servicesData.service4.services[1]}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12 p-0  pb-2 py-md-2 ">
                                    <div className="subservice4-3 ">
                                        <p className="dscrp-header p-0">
                                            3. {servicesData.service4.services[2]}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12 p-0  pb-2 py-md-2">
                                    <div className="subservice4-4 ">
                                        <p className="dscrp-header p-0">
                                            4. {servicesData.service4.services[3]}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-6 d-flex flex-column text-right">
                            <LazyLoadImage
                                alt="..."
                                className="w-100 h-100"
                                src={require("../images/services/s4-medium.webp")}
                                effect="blur"
                                wrapperProps={{
                                    style: {transitionDelay: "1s"},
                                }}
                            />
                            <a
                                onClick={() => {
                                    setActiveInformation('service')
                                    setServiceId("service4")
                                }}
                                href="#"
                                data-toggle="modal"
                                data-target="#moreInformationModal"
                                className={`btn  more-btn  ${currentLanguage === "fa" ? "more-btn-fa" : "more-btn-en"}`}
                            >
                                <span>
                                    {servicesData.service4.more_btn}
                                </span>
                                <i
                                    className="fa mx-1 fa-arrow-left"
                                    aria-hidden="true"
                                ></i>
                            </a>
                        </div>
                    </div>
                </div>
            )}
            {/*/!* ============= banner ============== *!/*/}
            <div className="container-fluid d-flex justify-content-center align-items-center banner1 bg-secondary">
                <h1>BANNER1</h1>
            </div>
            {/*/!* ============ service5 ============= *!/*/}
            {innerWidth >= 1250 ? (
                <div
                    id='service5'
                    className="container-fluid  service5"
                    style={{position: "relative", height: "960px", marginTop: "32px"}}
                >
                    <h2 className="service5-title d-flex justify-content-center align-items-center">
                        <span className={s4_5TitleClass}>
                          <span>{servicesData.service5.title[0]}</span>
                            &nbsp;
                            <span>{servicesData.service5.title[1]}</span>
                            &nbsp;
                            <span>{servicesData.service5.title[2]}</span>
                            &nbsp;
                            <span>{servicesData.service5.title[3]}</span>
                        </span>
                    </h2>
                    <img
                        src={require("../images/services/frame2.png")}
                        className="service5-frame"
                    />
                    <img
                        className="frame5"
                        src={require("../images/services/frame4.png")}
                    />
                    <div
                        className={
                            currentLanguage === "fa" ? "w-100" : "w-100 dscrp-cont-en"
                        }
                    >
                        <div className="service5-dscr1-container">
                            <p className="dscrp-header">
                                1- {servicesData.service5.services[0]}
                            </p>
                        </div>
                        <div className="service5-dscr2-container">
                            <p className="dscrp-header">
                                2- {servicesData.service5.services[1]}
                            </p>
                        </div>
                        <div className="service5-dscr3-container">
                            <p className="dscrp-header">
                                3- {servicesData.service5.services[2]}
                            </p>
                        </div>
                        <div
                            className={`service5-dscr4-container ${currentLanguage === "fa" ? "service5-fa" : "service5-en"}`}>
                            <p className="dscrp-header">
                                4- {servicesData.service5.services[3]}
                            </p>
                        </div>
                    </div>
                    <div className="w-100">
                        <a
                            onClick={() => {
                                setActiveInformation('service')
                                setServiceId("service5")
                            }}
                            href="#"
                            data-toggle="modal"
                            data-target="#moreInformationModal"
                            className={`btn more-btn d-flex justify-content-center align-items-center
                             ${currentLanguage === "fa" ? "more-btn-fa" : "more-btn-en "}`}
                        >
                            <span>{servicesData.service5.more_btn}</span>
                            <i className="fa mx-1 fa-arrow-left" aria-hidden="true"></i>
                        </a>
                    </div>

                    <LazyLoadImage
                        alt="..."
                        className="service5-img"
                        src={require("../images/services/s5.webp")}
                        effect="blur"
                        wrapperProps={{
                            style: {transitionDelay: "1s"},
                        }}
                    />
                </div>
            ) : (
                <div
                    id='service5'
                    style={{position: "relative", marginTop: "48px"}}
                    className="container-fluid  service5-small"
                >
                    <h1
                        className={
                            currentLanguage === "fa"
                                ? "service5-small-header text-right"
                                : "service5-small-header text-left"
                        }
                    >
                        {servicesData.service5.title[0]}
                        {servicesData.service5.title[1]}&nbsp;
                        {servicesData.service5.title[2]}&nbsp;
                        {servicesData.service5.title[3]}
                    </h1>
                    <div className="row justify-content-center">
                        <div className="col-6 pt-0  dscrp-container text-right overflow-hidden">
                            <div
                                style={{height: "95%"}}
                                className={currentLanguage === "fa" ? "row" : "row en"}
                            >
                                <div className="col-12 p-0 pb-2">
                                    <div className="subservice5-1">
                                        <p className="dscrp-header p-0">
                                            1. {servicesData.service5.services[0]}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12 p-0 pb-2 py-md-2">
                                    <div className="subservice5-2 ">
                                        <p className="dscrp-header p-0">
                                            2. {servicesData.service5.services[1]}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12 p-0  pb-2 py-md-2 ">
                                    <div
                                        style={innerWidth < 450 ? {overflow: 'auto'} : null}
                                        className="subservice5-3 ">
                                        <p className="dscrp-header p-0">
                                            3.{servicesData.service5.services[2]}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12 p-0  pb-2 py-md-2">
                                    <div
                                        style={innerWidth < 450 ? {overflow: 'auto'} : null}
                                        className="subservice5-4 ">
                                        <p className="dscrp-header p-0">
                                            4. {servicesData.service5.services[3]}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-6 d-flex flex-column text-right">
                            <LazyLoadImage
                                alt="..."
                                className=" h-100 w-100"
                                src={require("../images/services/s5-medium.webp")}
                                effect="blur"
                                wrapperProps={{
                                    style: {transitionDelay: "1s"},
                                }}
                            />
                            <a
                                onClick={() => {
                                    setActiveInformation('service')
                                    setServiceId("service5")
                                }}
                                href="#"
                                data-toggle="modal"
                                data-target="#moreInformationModal"
                                className={`btn  more-btn  ${currentLanguage === "fa" ? "more-btn-fa" : "more-btn-en "}`}
                            >
                                <span>
                                      {servicesData.service5.more_btn}
                                </span>
                                <i
                                    className="fa mx-1 fa-arrow-left"
                                    aria-hidden="true"
                                ></i>
                            </a>
                        </div>
                    </div>
                </div>
            )}
            {/*/!* ============ service6 ============= *!/*/}
            {innerWidth >= 1250 ? (
                <div
                    id='service6'
                    className="container-fluid service6"
                    style={{position: "relative", height: "480px", marginTop: "48px"}}
                >
                    <div className="w-100">
                        <h2 className="service6-title d-flex justify-content-center align-items-center">
                            {servicesData.service6.title}
                        </h2>
                    </div>
                    <img
                        src={require("../images/services/frame1.png")}
                        className="service6-frame"
                    />

                    <div style={{position: "relative"}}>
                        <div
                            className={
                                currentLanguage === "fa"
                                    ? "service6-dscr1-container"
                                    : "service6-dscr1-container en"
                            }
                        >
                            <p className="dscrp-header">
                                1- {servicesData.service6.services[0]}
                            </p>
                        </div>
                        <div
                            className={
                                currentLanguage === "fa"
                                    ? "service6-dscr2-container fa"
                                    : "service6-dscr2-container en"
                            }
                        >
                            <p className="dscrp-header">
                                2- {servicesData.service6.services[1]}
                            </p>
                        </div>
                    </div>

                    <div className="w-100">
                        <a
                            onClick={() => {
                                setActiveInformation('service')
                                setServiceId("service6")
                            }}
                            href="#"
                            data-toggle="modal"
                            data-target="#moreInformationModal"
                            className={`btn more-btn d-flex justify-content-center align-items-center
                             ${currentLanguage === "fa" ? "more-btn-fa" : "more-btn-en "}`}
                            style={{zIndex: "1"}}
                        >
                            <span>{servicesData.service6.more_btn}</span>
                            <i className="fa mx-1 fa-arrow-left" aria-hidden="true"></i>
                        </a>
                    </div>
                    <LazyLoadImage
                        alt="..."
                        className="service6-img"
                        src={require("../images/services/s6.webp")}
                        effect="blur"
                        wrapperProps={{
                            style: {transitionDelay: "1s"},
                        }}
                    />
                </div>
            ) : (
                <div
                    id='service6'
                    style={{position: "relative", marginTop: "48px"}}
                    className="container-fluid  service6-small"
                >
                    <h1
                        className={
                            currentLanguage === "fa"
                                ? "service6-small-header text-right"
                                : "service6-small-header text-left"
                        }
                    >
                        {servicesData.service6.title}
                    </h1>
                    <div className="row justify-content-center">
                        <div className="col-6 d-flex flex-column text-right overflow-hidden">
                            <LazyLoadImage
                                alt="..."
                                className=" h-100 w-100"
                                src={require("../images/services/s6-medium.webp")}
                                effect="blur"
                                wrapperProps={{
                                    style: {transitionDelay: "1s"},
                                }}
                            />
                            <a
                                onClick={() => {
                                    setActiveInformation('service')
                                    setServiceId("service6")
                                }}
                                href="#"
                                data-toggle="modal"
                                data-target="#moreInformationModal"
                                className={`btn  more-btn  ${currentLanguage === "fa" ? "more-btn-fa" : "more-btn-en "}`}
                            >
                                <span>
                                    {servicesData.service6.more_btn}
                                </span>
                                <i
                                    className="fa mx-1 fa-arrow-left"
                                    aria-hidden="true"
                                ></i>
                            </a>
                        </div>

                        <div className="col-6 pt-0  dscrp-container text-right">
                            <div
                                style={{height: "90%"}}
                                className={currentLanguage === "fa" ? "row" : "row en"}
                            >
                                <div className="col-12 p-0 pb-2">
                                    <div className="subservice6-1">
                                        <p className="dscrp-header p-0">
                                            1. {servicesData.service6.services[0]}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12 p-0  py-md-2">
                                    <div className="subservice6-2 ">
                                        <p className="dscrp-header p-0">
                                            2.{servicesData.service6.services[1]}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {/*/!* ============ service7 ============= *!/*/}

            {innerWidth >= 1250 ? (
                <div
                    id='service7'
                    style={{position: "relative", height: "372px", marginTop: "48px"}}
                    className="container-fluid  service7"
                >
                    <LazyLoadImage
                        alt="..."
                        className="service7-img"
                        src={require("../images/services/s7.webp")}
                        effect="blur"
                        wrapperProps={{
                            style: {transitionDelay: "1s"},
                        }}
                    />

                    <div className="d-flex flex-column text-right">
                        <div className="w-100">
                            <h2 className="service7-title d-flex justify-content-center align-items-center">
                                {servicesData.service7.title}
                            </h2>
                        </div>
                        <div className="w-100">
                            <div
                                className={
                                    currentLanguage === "fa"
                                        ? "service7-dscr-container"
                                        : "service7-dscr-container en"
                                }
                            >
                                <p className="dscrp-text">
                                    {servicesData.service7.description_summary}
                                </p>
                                <ul className="dscrp-list">
                                    <li className="dscrp-list-item">
                                        {servicesData.service7.services[0]}
                                    </li>
                                    <li className="dscrp-list-item">
                                        {servicesData.service7.services[1]}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="w-100">
                            <a
                                onClick={() => {
                                    setActiveInformation('service')
                                    setServiceId("service7")
                                }}
                                href="#"
                                data-toggle="modal"
                                data-target="#moreInformationModal"
                                className={`btn more-btn d-flex justify-content-center align-items-center 
                                 ${currentLanguage === "fa" ? "more-btn-fa" : "more-btn-en "}`}
                            >
                                <span> {servicesData.service7.more_btn}</span>
                                <i className="fa mx-1 fa-arrow-left" aria-hidden="true"></i>
                            </a>
                        </div>
                    </div>
                </div>
            ) : (
                <div
                    id='service7'
                    style={{position: "relative", marginTop: "48px"}}
                    className="container-fluid  service7-small"
                >
                    <h1
                        className={
                            currentLanguage === "fa"
                                ? "service7-small-header text-right"
                                : "service7-small-header text-left"
                        }
                    >
                        {servicesData.service7.title}
                    </h1>
                    <div className="row justify-content-center">
                        <div
                            className="col-6 text-right ">
                            <div
                                className={
                                    currentLanguage === "fa"
                                        ? "dscrp-container"
                                        : "dscrp-container en"
                                }
                            >
                                <p className={`dscrp ${innerWidth < 450 ? "d-none" : "d-block"}`}>
                                    {servicesData.service7.description_summary}
                                </p>
                                <ul className={`d-flex flex-column ${currentLanguage === 'fa' ? "pl-0 pr-3" : "pr-0 pl-3"}`}>
                                    <li> {servicesData.service7.services[0]} </li>
                                    <li> {servicesData.service7.services[1]} </li>
                                </ul>
                            </div>
                        </div>
                        <div
                            style={innerWidth < 1250 ? {paddingRight: '0'} : null}
                            className="col-6 d-flex flex-column">
                            <LazyLoadImage
                                alt="..."
                                className="w-100 h-100"
                                src={require("../images/services/s7-medium.webp")}
                                effect="blur"
                                wrapperProps={{
                                    style: {transitionDelay: "1s"},
                                }}
                            />
                            <a
                                onClick={() => {
                                    setActiveInformation('service')
                                    setServiceId("service7")
                                }}
                                href="#"
                                data-toggle="modal"
                                data-target="#moreInformationModal"
                                className={`btn  more-btn  ${currentLanguage === "fa" ? "more-btn-fa" : "more-btn-en "}`}
                            >
                                <span>
                                    {servicesData.service7.more_btn}
                                </span>
                                <i
                                    className="fa mx-1 fa-arrow-left"
                                    aria-hidden="true"
                                ></i>
                            </a>
                        </div>
                    </div>
                </div>
            )}
            {/* ============= banner ============== */}
            <div className="container-fluid d-flex justify-content-center align-items-center banner2 bg-secondary">
                <h1>BANNER2</h1>
            </div>
        </section>
    );
}
