import React, {useContext, useEffect} from "react";
import {infoModalIdContext} from "./infoModalIdProvider";


export default function InfoModal({servicesData, modalData, aboutusData, currentLanguage, sliderData}) {

    const {
        serviceId,
        activeInformation,
        sliderId
    } = useContext(infoModalIdContext);





    return (
        <>
            <div
                className="modal fade"
                id="moreInformationModal"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog  modal-dialog-centered ">
                    <div className="modal-content">
                        <div className="modal-header p-0">
                            <img
                                className=" modal-img"
                                src={require("../../src/images/ian-taylor-jOqJbvo1P9g-unsplash.webp")}
                                alt="modal-img"
                            />
                        </div>
                        <div className="modal-body pb-0">
                            <h1 className="text-center"> {modalData[0]} </h1>
                            <hr/>
                            <p className={`title ${currentLanguage === 'fa' ? "text-right" : "text-left"}`}>
                                {
                                    activeInformation === 'about' ?
                                        aboutusData[0] :
                                        activeInformation === 'service' ?
                                            servicesData[serviceId].complete_title :
                                            activeInformation === 'slider' ?
                                                sliderData[sliderId].title : ""

                                }
                            </p>
                            <p className={`description mb-0 ${currentLanguage === 'fa' ? "fa" : "en"}`}>
                                {
                                    activeInformation === 'about' ?
                                        aboutusData[1] :
                                        activeInformation === 'service' ?
                                            servicesData[serviceId].description :
                                            activeInformation === 'slider' ?
                                                sliderData[sliderId].description : ""
                                }
                            </p>
                        </div>
                        <div className="modal-footer d-flex flex-column align-items-end">
                            <div className="container-fluid d-flex justify-content-center">
                                <button
                                    type="button"
                                    className="btn btn-secondary modal-close-btn"
                                    data-dismiss="modal"
                                >
                                    {modalData[3]}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}