import Banner from "./components/banner";
import Services from "./components/services";
import AboutUs from "./components/aboutUs";
import Projects from "./components/projects";
import ComponiesSlider from "./components/companiesSlider";
import Navbar from "./components/navbar";
import Footer from "./components/footer";
import React, {useEffect} from "react";
import siteData from "./siteData";
import ContactModal from "./components/contactModal";
import InfoModal from "./components/infoModal";
import InfoModalIdProvider from "./components/infoModalIdProvider";

export default function App() {


    const [currentLanguage, setCurrentLanguage] = React.useState(localStorage.getItem('lang') || 'en');
    const [navLangData, setNavLangData] = React.useState(siteData.navLang.enNav);
    const [modalData, setModalData] = React.useState(siteData.modalData.enModal);
    const [servicesData, setServicesData] = React.useState(
        siteData.servicesData.enServices
    );
    const [aboutusData, setAboutusData] = React.useState(
        siteData.aboutusData.aboutusEn
    );
    const [sliderData, setSliderData] = React.useState(
        siteData.sliderData.EnSlider
    );
    const [projectData, setProjectData] = React.useState(
        siteData.projectsData.EnProjects
    );
    const [footerData, setFooterData] = React.useState(
        siteData.footerAddress.enAddress
    );

    // =====================================================================================

    function changeDocLang(lang) {
        document.documentElement.lang = lang;
        setCurrentLanguage(lang);
    }

    // ========================================================================================

    // handle contact hash:
    useEffect(() => {
        console.log(window.location.hash)
        if (window.location.hash === '#contact') {
            document.querySelector('.contact-us-btn').click();
        }
    }, []);


    React.useEffect(() => {
        if (document.documentElement.lang.startsWith("en")) {
            document.body.style.direction = "ltr";
        }

        localStorage.setItem('lang', currentLanguage)


        setNavLangData(
            currentLanguage === "fa" ? siteData.navLang.faNav : siteData.navLang.enNav
        );
        setModalData(
            currentLanguage === "fa"
                ? siteData.modalData.faModal
                : siteData.modalData.enModal
        );
        setServicesData(
            currentLanguage === "fa"
                ? siteData.servicesData.faServices
                : siteData.servicesData.enServices
        );
        setAboutusData(
            currentLanguage === "fa"
                ? siteData.aboutusData.aboutusFa
                : siteData.aboutusData.aboutusEn
        );
        setSliderData(
            currentLanguage === "fa"
                ? siteData.sliderData.FaSlider
                : siteData.sliderData.EnSlider
        );
        setProjectData(
            currentLanguage === "fa"
                ? siteData.projectsData.FaProjects
                : siteData.projectsData.EnProjects
        );
        setFooterData(
            currentLanguage === "fa"
                ? siteData.footerAddress.faAddress
                : siteData.footerAddress.enAddress
        );
    }, [currentLanguage]);

    return (
        <InfoModalIdProvider>
            {/* <!--================= Modal =========================--> */}
            <ContactModal modalData={modalData} currentLanguage={currentLanguage}/>

            <>
                <InfoModal
                    modalData={modalData}
                    servicesData={servicesData}
                    currentLanguage={currentLanguage}
                    aboutusData={aboutusData}
                    sliderData={sliderData}
                />
            </>
            {/* ============================================================ */}
            <Navbar
                tools={{
                    changeDocLang: changeDocLang,
                    navLangData: navLangData,
                    currentLanguage: currentLanguage,
                }}
            />
            <Banner/>

            <>
                <Services
                    servicesData={servicesData}
                    currentLang={currentLanguage}
                />
            </>


            <AboutUs
                aboutusData={{
                    aboutusData: aboutusData,
                    currentLanguage: currentLanguage,
                }}
            />
            <ComponiesSlider
                sliderData={sliderData}
                currentLang={currentLanguage}
            />
            <Projects
                projectData={{
                    projectData: projectData,
                    currentLanguage: currentLanguage,
                }}
            />
            <Footer
                footerData={{
                    footerData: footerData,
                    currentLanguage: currentLanguage,
                }}
            />

        </InfoModalIdProvider>
    );
}
