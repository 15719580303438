export default function Banner() {
  return (
    <section
      id="start"
      style={{ height: "600px" }}
      className="container-fluid main-banner bg-secondary d-flex justify-content-center align-items-center"
    >
      <h1>BANNER</h1>
    </section>
  );
}
