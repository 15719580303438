import React, {useState} from "react";
import logo from '../images/brand/logo.svg'
import phonecall from '../../src/images/phone-call-svgrepo-com.svg'
import CustomCountrySelector from "./countrySelect";
import gb_flag from "../images/flags/gb.svg";
import ir_flag from '../images/flags/ir.svg'
import location from '../images/icons/location_white.svg'
import phone from '../images/icons/phone_white.svg'
import mobile from '../images/icons/cell_phone_white.svg'
import arrow_down from '../images/icons/down-arrow.svg'
import arrow_right from '../images/icons/arrow-right.svg'
import bars from '../images/icons/menu.svg'


let sidenavIsOpen = false;

export default function Navbar(props) {


    const [innerWidth, setInnerWidth] = React.useState(window.innerWidth);
    const [currentLanguage, setCurrentLanguage] = React.useState(
        props.tools.currentLanguage
    );
    const language_ref = React.useRef(null);
    const [selectedCountry, setSelectedCountry] = useState({});

    const small_logo = (
        <img
            className="logo-img"
            src={logo}
        />
    );


    // ------------------- current language setter:
    React.useEffect(() => {
        setCurrentLanguage(props.tools.currentLanguage);
        language_ref.current = props.tools.currentLanguage;

        if (props.tools.currentLanguage === 'fa') {

            setSelectedCountry({code: 'IR', name: 'Iran', flag:ir_flag})
        } else {
            setSelectedCountry({code: 'GB', name: 'United Kingdom', flag: gb_flag})
        }

    }, [props]);

    // ------------------ sidenav controller:
    React.useEffect(() => {
        if (innerWidth > 1199) {
            document.querySelector(".cover").style.display = "none";
            document.querySelector(".sidenav").style.transform =
                `${currentLanguage === "fa" ? "translateX(300px)" : "translateX(-300px)"}`;
        }
        if (innerWidth <= 1199) {
            // close sidenav when click out of sidenav(retrigger Event listener every time):
            document.querySelector(".cover").addEventListener("click", () => {
                if (sidenavIsOpen) {
                    closeNav();
                }
            });
        }

        window.addEventListener("resize", () => {
            setInnerWidth(window.innerWidth);
        });
        return () => {
            window.removeEventListener("resize", () => {
            });
        };
    }, [innerWidth, currentLanguage]);


    // ----------------- accordion controller:
    React.useEffect(() => {
        var acc = document.getElementsByClassName("accordion");
        var i;
        for (i = 0; i < acc.length; i++) {
            acc[i].addEventListener("click", function () {
                this.classList.toggle("active");
                var panel = this.nextElementSibling;
                if (panel.style.maxHeight) {
                    panel.style.maxHeight = null;
                } else {
                    panel.style.maxHeight = panel.scrollHeight + "px";
                }
            });
        }
    }, []);


    // ----------------- fragment effect:
    React.useEffect(() => {
        document.querySelectorAll(".nav-link").forEach((link) => {
            link.addEventListener("click", (event) => {
                event.preventDefault();
                const sectionID = event.target.dataset.section;
                window.scrollTo(0, document.getElementById(sectionID).offsetTop - 100);
                // Update history with section ID as state
                window.history.pushState({section: sectionID}, null, `#${sectionID}`);
            });
        });

    }, []);


    // =============================== sidenav functions:
    function openNav() {
        sidenavIsOpen = true;
        document.getElementById("mySidenav").style.transform = "translateX(0px)";
        document.body.style.overflow = "hidden";
        document.querySelector(".cover").style.display = "block";
    }

    function closeNav() {
        sidenavIsOpen = false;
        document.getElementById("mySidenav").style.transform =
            `${language_ref.current === 'fa' ? "translateX(300px)" : "translateX(-300px)"}`;
        document.body.style.overflowY = "auto";
        document.body.style.overflowX = "hidden";
        document.querySelector(".cover").style.display = "none";
    }

    function rotateArrow(btnclass) {
        document.querySelector(`.${btnclass} img`).classList.toggle("active");
    }

    // ===============================country select funtion:

    function handleCountrySelect(value) {
        if (value) {
            setSelectedCountry(value);
            const lang = value.code === "GB" ? 'en'
                : value.code === 'IR' ? 'fa'
                    : value.code === 'RU' ? 'en'
                        : value.code === "AE" ? 'en' : ""
            props.tools.changeDocLang(lang)
        }
    }


    return (
        <>

            <nav
                className={`navbar navbar-expand-xl  main-navbar ${
                    currentLanguage === "fa" ? "fa" : "en"
                }`}
            >
                <div
                    className="container-fluid">
                    <div className="d-flex align-items-center">
                        <a
                            href="#"
                            data-toggle="modal"
                            data-target="#contactModal"
                            className={
                                currentLanguage === "fa"
                                    ? "contact-us-btn btn fa"
                                    : "contact-us-btn btn en"
                            }
                        >
                            {
                                innerWidth > 500 ?
                                    props.tools.navLangData[5]
                                    :
                                    <img src={phonecall} alt="phone call"/>
                            }
                        </a>
                        {/* =================== */}
                        <div className='country-selector-container'>
                            <CustomCountrySelector
                                selectedCountry={selectedCountry}
                                handleCountrySelect={handleCountrySelect}
                            />
                        </div>
                        {/* =================== */}

                    </div>

                    <div className="collapse navbar-collapse ">
                        <ul
                            style={
                                currentLanguage === "fa"
                                    ? {direction: "rtl"}
                                    : {direction: "ltr"}
                            }
                            className="navbar-nav  mb-2 mb-lg-0"
                        >
                            <li className="nav-item">
                                <a
                                    href="#start"
                                    data-section="start"
                                    style={{marginRight: "23px"}}
                                    className="nav-link  "
                                >
                                    {props.tools.navLangData[0]}
                                </a>
                            </li>
                            <li className="nav-item n3">
                                <a
                                    href="#companies"
                                    data-section="companies"
                                    style={{marginRight: "24px"}}
                                    className="nav-link  "
                                >
                                    {props.tools.navLangData[1][0]}
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    href="#projects"
                                    data-section="projects"
                                    style={{marginRight: "24px"}}
                                    className="nav-link  "
                                >
                                    {props.tools.navLangData[2][0]}
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    href="#service"
                                    data-section="service"
                                    style={{marginRight: "24px"}}
                                    className="nav-link  "
                                >
                                    {props.tools.navLangData[3][0]}
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    href="#"
                                    data-toggle="modal"
                                    data-target="#contactModal"
                                    style={{marginRight: "24px"}}
                                    className="invite-link  "
                                >
                                    {props.tools.navLangData[4]}
                                </a>
                            </li>
                        </ul>
                    </div>

                    <span className={`logo ${currentLanguage === "fa" ? "fa" : "en"}`}>
            {innerWidth > 1199 ? (
                <img
                    className="logo-img"
                    src={logo}
                />
            ) : (
                small_logo
            )}
          </span>

                    {/* ================================= sidebar toggler */}
                    <button
                        className={`btn sidebar-btn  ${
                            currentLanguage === "fa" ? "fa" : "en"
                        }`}
                        type="button"
                        onClick={openNav}
                    >
                        <img src={bars} alt="bar"/>
                    </button>
                </div>
            </nav>


            {/* ====================== sidebar ======================== */
            }

            <div
                id="mySidenav"
                className={`sidenav 
                ${currentLanguage === "fa" ? "fa" : "en"}
                ${innerWidth < 1200 ? "d-block" : "d-none"}
                `}
            >

                <div className="d-flex justify-content-between align-items-center">

                    <div className='country-selector-container'>
                        <CustomCountrySelector
                            selectedCountry={selectedCountry}
                            handleCountrySelect={handleCountrySelect}
                        />
                    </div>


                    <h1 style={{fontSize: '20px'}} className='text-light m-auto'>H.Kered.co</h1>

                    <button
                        type="button"
                        className="btn"
                        style={{marginRight: "14px"}}
                        onClick={closeNav}
                    >
                        <img
                            style={currentLanguage === 'en' ?
                                {rotate: '-180deg'} : {rotate: '0deg'}}
                            src={arrow_right}
                            alt="arrow right"
                        />
                    </button>


                </div>

                <div className='sidenav-ul-wrapper'>
                    <ul
                        className="navbar-nav text-right mt-4"
                    >
                        <li className="nav-item">
                            <a
                                onClick={closeNav}
                                href="#start"
                                data-section="start"
                                style={{marginRight: "23px"}}
                                className="nav-link  text-white "
                            >
                                {props.tools.navLangData[0]}
                            </a>
                        </li>
                        <div
                            className={`ml-auto nav-item ac-container mt-3 ${
                                currentLanguage === "fa" ? "fa" : "en"
                            }`}
                        >
                            <button
                                className="btn  accordion companies-accordion-btn"
                                type="button"
                                onClick={() => {
                                    rotateArrow("companies-accordion-btn");
                                }}
                            >
                                <img className='mx-2 chevron-down' src={arrow_down} alt="arrow down"/>
                                <span
                                    className="text-white pr-2">
                                {props.tools.navLangData[1][0]}
                            </span>
                            </button>
                            <li className="nav-item panel companies-accordion">
                                <ul
                                    style={{marginRight: "25px"}}
                                    className="nav flex-column pb-4"
                                >
                                    <li className="nav-item ">
                                        <a
                                            onClick={closeNav}
                                            style={currentLanguage === 'fa' ? {fontWeight: "100"} : null}
                                            href="#companies"
                                            data-section="companies"
                                            className="nav-link text-light"
                                        >
                                            {props.tools.navLangData[1][1]}
                                        </a>
                                    </li>
                                    <li className="nav-item ">
                                        <a
                                            onClick={closeNav}
                                            style={currentLanguage === 'fa' ? {fontWeight: "100"} : null}
                                            href="#companies"
                                            data-section="companies"
                                            className="nav-link text-light"
                                        >
                                            {props.tools.navLangData[1][2]}
                                        </a>
                                    </li>
                                    <li className="nav-item ">
                                        <a
                                            onClick={closeNav}
                                            style={currentLanguage === 'fa' ? {fontWeight: "100"} : null}
                                            href="#companies"
                                            data-section="companies"
                                            className="nav-link text-light"
                                        >
                                            {props.tools.navLangData[1][3]}
                                        </a>
                                    </li>
                                    <li className="nav-item ">
                                        <a
                                            onClick={closeNav}
                                            style={currentLanguage === 'fa' ? {fontWeight: "100"} : null}
                                            href="#companies"
                                            data-section="companies"
                                            className="nav-link text-light"
                                        >
                                            {props.tools.navLangData[1][4]}
                                        </a>
                                    </li>
                                    <li className="nav-item ">
                                        <a
                                            onClick={closeNav}
                                            style={currentLanguage === 'fa' ? {fontWeight: "100"} : null}
                                            href="#companies"
                                            data-section="companies"
                                            className="nav-link text-light"
                                        >
                                            {props.tools.navLangData[1][5]}
                                        </a>
                                    </li>
                                    <li className="nav-item ">
                                        <a
                                            onClick={closeNav}
                                            style={currentLanguage === 'fa' ? {fontWeight: "100"} : null}
                                            href="#companies"
                                            data-section="companies"
                                            className="nav-link text-light"
                                        >
                                            {props.tools.navLangData[1][6]}
                                        </a>
                                    </li>
                                    <li className="nav-item ">
                                        <a
                                            onClick={closeNav}
                                            style={currentLanguage === 'fa' ? {fontWeight: "100"} : null}
                                            href="#companies"
                                            data-section="companies"
                                            className="nav-link text-light"
                                        >
                                            {props.tools.navLangData[1][7]}
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </div>
                        <div
                            className={`ml-auto nav-item ac-container mt-3 ${
                                currentLanguage === "fa" ? "fa" : "en"
                            }`}
                        >
                            <button
                                className="btn  accordion projects-accordion-btn"
                                type="button"
                                onClick={() => {
                                    rotateArrow("projects-accordion-btn");
                                }}
                            >
                                <img className='mx-2 chevron-down' src={arrow_down} alt="arrow down"/>
                                <span
                                    className="text-white pr-2">
                                {props.tools.navLangData[2][0]}
                             </span>
                            </button>
                            <li className="nav-item panel projects-accordion">
                                <ul
                                    style={{marginRight: "25px"}}
                                    className="nav flex-column pb-4"
                                >
                                    <li className="nav-item ">
                                        <a
                                            onClick={closeNav}
                                            style={currentLanguage === 'fa' ? {fontWeight: "100"} : null}
                                            href="#projects"
                                            data-section="projects"
                                            className="nav-link text-light"
                                        >
                                            {props.tools.navLangData[2][1]}
                                        </a>
                                    </li>
                                    <li className="nav-item ">
                                        <a
                                            onClick={closeNav}
                                            style={currentLanguage === 'fa' ? {fontWeight: "100"} : null}
                                            href="#projects"
                                            data-section="projects"
                                            className="nav-link text-light"
                                        >
                                            {props.tools.navLangData[2][2]}
                                        </a>
                                    </li>
                                    <li className="nav-item ">
                                        <a
                                            onClick={closeNav}
                                            style={currentLanguage === 'fa' ? {fontWeight: "100"} : null}
                                            href="#projects"
                                            data-section="projects"
                                            className="nav-link text-light"
                                        >
                                            {props.tools.navLangData[2][3]}
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </div>
                        <div
                            className={`ml-auto nav-item ac-container mt-3 ${
                                currentLanguage === "fa" ? "fa" : "en"
                            }`}
                        >
                            <button
                                className={`btn  accordion services-accordion-btn
                                ${currentLanguage === 'fa' ? "text-right pr-3" : "text-center"}`}
                                type="button"
                                onClick={() => {
                                    rotateArrow("services-accordion-btn");
                                }}
                            >
                                <img className='mx-2 chevron-down' src={arrow_down} alt="arrow down"/>
                                <span className="text-white pr-2">
                                 {props.tools.navLangData[3][0]}
                            </span>
                            </button>
                            <li className="nav-item panel services-accordion">
                                <ul
                                    style={{marginRight: "25px"}}
                                    className="nav flex-column pb-4"
                                >
                                    <li className="nav-item ">
                                        <a
                                            onClick={closeNav}
                                            style={currentLanguage === 'fa' ? {fontWeight: "100"} : null}
                                            href="#service1"
                                            data-section="service1"
                                            className="nav-link text-light"
                                        >
                                            {props.tools.navLangData[3][1]}
                                        </a>
                                    </li>
                                    <li className="nav-item ">
                                        <a
                                            onClick={closeNav}
                                            style={currentLanguage === 'fa' ? {fontWeight: "100"} : null}
                                            href="#service2"
                                            data-section="service2"
                                            className="nav-link text-light"
                                        >
                                            {props.tools.navLangData[3][2]}
                                        </a>
                                    </li>
                                    <li className="nav-item ">
                                        <a
                                            onClick={closeNav}
                                            style={currentLanguage === 'fa' ? {fontWeight: "100"} : null}
                                            href="#service3"
                                            data-section="service3"
                                            className="nav-link text-light"
                                        >
                                            {props.tools.navLangData[3][3]}
                                        </a>
                                    </li>
                                    <li className="nav-item ">
                                        <a
                                            onClick={closeNav}
                                            style={currentLanguage === 'fa' ? {fontWeight: "100"} : null}
                                            href="#service4"
                                            data-section="service4"
                                            className="nav-link text-light"
                                        >
                                            {props.tools.navLangData[3][4]}
                                        </a>
                                    </li>
                                    <li className="nav-item ">
                                        <a
                                            onClick={closeNav}
                                            style={currentLanguage === 'fa' ? {fontWeight: "100"} : null}
                                            href="#service5"
                                            data-section="service5"
                                            className="nav-link text-light"
                                        >
                                            {props.tools.navLangData[3][5]}
                                        </a>
                                    </li>
                                    <li className="nav-item ">
                                        <a
                                            onClick={closeNav}
                                            style={currentLanguage === 'fa' ? {fontWeight: "100"} : null}
                                            href="#service6"
                                            data-section="service6"
                                            className="nav-link text-light"
                                        >
                                            {props.tools.navLangData[3][6]}
                                        </a>
                                    </li>
                                    <li className="nav-item ">
                                        <a
                                            onClick={closeNav}
                                            style={currentLanguage === 'fa' ? {fontWeight: "100"} : null}
                                            href="#service7"
                                            data-section="service7"
                                            className="nav-link text-light"
                                        >
                                            {props.tools.navLangData[3][7]}
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </div>
                        <li className="nav-item mt-3">
                            <a
                                onClick={closeNav}
                                href="#"
                                data-toggle="modal"
                                data-target="#contactModal"
                                style={{marginRight: "24px"}}
                                className="invite-link-side  text-white"
                            >
                                {props.tools.navLangData[4]}
                            </a>
                        </li>
                        <li className="nav-item mt-3">
                            <a
                                onClick={closeNav}
                                href="#"
                                style={{marginRight: "24px"}}
                                className="awards-link  text-white"
                            >
                                {props.tools.navLangData[6]}
                            </a>
                        </li>
                        <li className="nav-item mt-3">
                            <a
                                onClick={closeNav}
                                href="#"
                                style={{marginRight: "24px"}}
                                className="qa-link  text-white"
                            >
                                {props.tools.navLangData[7]}
                            </a>
                        </li>
                    </ul>
                </div>


                <div
                    className={
                        currentLanguage === "fa" ? "sidenav-footer" : "sidenav-footer en"
                    }
                >
                    <hr style={{opacity: "1", color: "white", width: "80%"}}/>
                    <p className={`d-flex  justify-content-center px-3 ${currentLanguage === 'en' ? "pl-2" : ""}`}>
                        <span className={`text-light ${currentLanguage === 'en' ? "text-left" : "text-right"}`}>
                            {props.tools.navLangData[8]}
                        </span>
                        <img className='mx-1' src={location} alt="location"/>
                    </p>
                    <p className={`d-flex  justify-content-center px-3 ${currentLanguage === 'en' ? "pl-2" : ""}`}>
                        <span className={`text-light ${currentLanguage === 'en' ? "text-left" : "text-right"}`}
                              style={currentLanguage === 'fa' ? {direction: 'rtl'} : {direction: 'ltr'}}
                        >
                          +98 (11) 44241662
                        </span>
                        <img className='mx-1' src={phone} alt="location"/>
                    </p>
                    <p className={`d-flex  justify-content-center align-items-center px-3 ${currentLanguage === 'en' ? "pl-2" : ""}`}>
                        <span className={`text-light ${currentLanguage === 'en' ? "text-left" : "text-right"}`}
                              style={currentLanguage === 'fa' ? {direction: 'rtl'} : {direction: 'ltr'}}
                        >
                             +989113118955
                        </span>
                        <img className='mx-1' src={mobile} alt="location"/>
                    </p>


                </div>

            </div>
        </>
    );
}
