import React from "react";
import {LazyLoadImage} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import arrow_right from '../images/icons/arrow-right-black.svg'

export default function Projects(props) {
    const [class1, setClass1] = React.useState("col-2  p-0");
    const [innerWidth, setInnerWidth] = React.useState(window.innerWidth);
    const [currentLanguage, setCurrentLanguage] = React.useState(
        props.projectData.currentLanguage
    );

    React.useEffect(() => {
        setCurrentLanguage(props.projectData.currentLanguage);
    }, [props]);

    React.useEffect(() => {
        if (innerWidth <= 600) {
            setClass1("col-4");
        } else if (innerWidth <= 991) {
            setClass1("col-3");
        } else {
            setClass1('col-2')
        }

        window.addEventListener("resize", () => {
            setInnerWidth(window.innerWidth);
        });
        return () => {
            window.removeEventListener("resize", () => {
            });
        };
    }, [innerWidth]);

    return (
        <section
            className="conatainer-fluid  projects-container "
            style={{marginTop: "32px"}}
            id="projects"
        >
            <h1
                className={`position-relative projects-title w-100 ${
                    currentLanguage === "fa" ? "text-right fa" : "text-left en"
                }`}
            >
                {props.projectData.projectData[0]}
                <a
                    href="#"
                    data-toggle="modal"
                    data-target="#moreInformationModal"
                    className={currentLanguage === "fa" ? "all-btn fa" : "all-btn en"}
                >
                    <span
                        style={currentLanguage === 'fa' ? {order: '1'} : {order: "0"}}
                        className="mx-2"
                    >
                        {props.projectData.projectData[1]}
                    </span>
                    <img
                        style={currentLanguage === 'en' ? {rotate: '0deg'} : {rotate: '180deg'}}
                        src={arrow_right}
                        alt="arrow right"
                    />
                </a>
            </h1>

            <div className="container-fluid p-0 img-container">
                <div className="row justify-content-center p-0">

                    <div className={`service1 service ${class1}`}>
                        <LazyLoadImage
                            alt="..."
                            className="img-fluid position-relative"
                            src={require("../images/projects/p1.png")}
                            effect="blur"
                            wrapperProps={{
                                style: {transitionDelay: "1s"},
                            }}
                        />
                        <span className="text-light text-center project-header">
              {props.projectData.projectData[2]}
            </span>
                        <span className="text-light project-subheader">
              {props.projectData.projectData[3]}
            </span>
                    </div>

                    <div className={`service2 service ${class1}`}>
                        <LazyLoadImage
                            alt="..."
                            className="img-fluid position-relative"
                            src={require("../images/projects/p2.png")}
                            effect="blur"
                            wrapperProps={{
                                style: {transitionDelay: "1s"},
                            }}
                        />
                        <span className="text-light text-center project-header">
              {props.projectData.projectData[4]}
            </span>
                        <span className="text-light project-subheader">
              {props.projectData.projectData[5]}
            </span>
                    </div>

                    <div className={`service3 service ${class1}`}>
                        <LazyLoadImage
                            alt="..."
                            className="img-fluid position-relative"
                            src={require("../images/projects/p3.png")}
                            effect="blur"
                            wrapperProps={{
                                style: {transitionDelay: "1s"},
                            }}
                        />
                        <span className="text-light text-center project-header">
              {props.projectData.projectData[6]}
            </span>
                        <span className="text-light project-subheader">
              {props.projectData.projectData[7]}
            </span>
                    </div>

                    <div className={`service4 service ${class1}`}>
                        <LazyLoadImage
                            alt="..."
                            className="img-fluid position-relative"
                            src={require("../images/projects/p4.png")}
                            effect="blur"
                            wrapperProps={{
                                style: {transitionDelay: "1s"},
                            }}
                        />
                        <span className="text-light text-center project-header">
              {props.projectData.projectData[8]}
            </span>
                        <span className="text-light project-subheader">
              {props.projectData.projectData[9]}
            </span>
                    </div>

                    <div className={`service5 service ${class1}`}>
                        <LazyLoadImage
                            alt="..."
                            className="img-fluid position-relative"
                            src={require("../images/projects/p1.png")}
                            effect="blur"
                            wrapperProps={{
                                style: {transitionDelay: "1s"},
                            }}
                        />
                        <span className="text-light text-center project-header">
              {props.projectData.projectData[10]}
            </span>
                        <span className="text-light project-subheader">
              {props.projectData.projectData[11]}
            </span>
                    </div>

                    <div className={`service6 service ${class1}`}>
                        <LazyLoadImage
                            alt="..."
                            className="img-fluid position-relative"
                            src={require("../images/projects/p2.png")}
                            effect="blur"
                            wrapperProps={{
                                style: {transitionDelay: "1s"},
                            }}
                        />
                        <span className="text-light text-center project-header">
              {props.projectData.projectData[12]}
            </span>
                        <span className="text-light project-subheader">
              {props.projectData.projectData[13]}
            </span>
                    </div>

                </div>
            </div>
        </section>
    );
}
