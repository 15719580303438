import React from 'react';
import ir_flag from '../images/flags/ir.svg'
import ru_flag from '../images/flags/ru.svg'
import ae_flag from '../images/flags/ae.svg'
import gb_flag from '../images/flags/gb.svg'

const countries = [
    {code: 'IR', name: 'Iran', flag: ir_flag},
    {code: 'RU', name: 'Russia', flag: ru_flag},
    {code: 'GB', name: 'United Kingdom', flag: gb_flag},
    {code: 'AE', name: 'United Arab Emirates', flag: ae_flag},
];

const CustomCountrySelector = ({handleCountrySelect,selectedCountry}) => {

    return (
        <div
            style={{width: "70px", height: '43px'}}
            className="dropdown country-selector-container">
            <button
                style={{background: 'lightgrey'}}
                className="btn  border w-100 h-100  mx-2 dropdown-toggle d-flex align-items-center"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-expanded="false"
            >
                <img src={selectedCountry.flag} alt="flag"/>
            </button>
            <ul className="dropdown-menu  " aria-labelledby="dropdownMenuButton">
                {countries.map((country) => (
                    <li key={country.code}>
                        <button
                            className="dropdown-item d-flex align-items-center"
                            onClick={() => handleCountrySelect(country)}
                        >
                            <img src={country.flag} alt="flag"/>
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default CustomCountrySelector;
