import React, {useContext, useState} from "react";
import {LazyLoadImage} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import arrow_right from '../images/icons/chevron-right.svg'
import arrow_left from '../images/icons/chevron-left.svg'
import {infoModalIdContext} from "./infoModalIdProvider";

export default function ComponiesSlider({sliderData, currentLang}) {

    const {setSliderId,setActiveInformation} = useContext(infoModalIdContext);

    const [currentLanguage, setCurrentLanguage] = React.useState(currentLang);

    const [innerWidth, setInnerWidth] = useState(window.innerWidth);

    React.useEffect(() => {
        setCurrentLanguage(currentLang);
    }, [currentLang]);

    React.useEffect(() => {

        window.addEventListener("resize", () => {
            setInnerWidth(window.innerWidth);
        });
        return () => {
            window.removeEventListener("resize", () => {
            });
        };
    }, [innerWidth]);

    return (
        <section
            className="containser-fluid companies"
            style={{marginTop: "32px"}}
            id="companies"
        >
            <h1
                className={
                    currentLanguage === "fa" ? "companies-title" : "companies-title en"
                }
            >
                {sliderData.title}
            </h1>
            <div
                style={{height: "400px"}}
                id="carouselExampleIndicators"
                className="carousel  slide"
                data-bs-ride="carousel"
            >
                <div className="carousel-inner">

                    {/*HPS*/}
                    <div className="carousel-item  active" data-interval="3000">
                        <LazyLoadImage
                            alt="..."
                            className="slide"
                            src={require("../images/companies/c1.webp")}
                            effect="blur"
                            wrapperProps={{
                                style: {transitionDelay: "1s"},
                            }}
                        />

                        <div
                            data-toggle="modal"
                            data-target="#moreInformationModal"
                            onClick={() => {
                                setActiveInformation('slider')
                                setSliderId('s1')
                            }}
                            className="slide-dscrp-container overflow-hidden"
                        >
                            <div className="row h-100">

                                <div
                                    className={`d-flex p-0 ${innerWidth <= 500 ? "flex-column justify-content-center" : (innerWidth > 500 && currentLanguage === 'fa') ? "align-items-center justify-content-end" : "align-items-center justify-content-start"}`}>
                                    <div
                                        className={`logo ${(currentLanguage === 'fa' && innerWidth > 500) ? "order-1" : ""}`}>
                                        <img
                                            className="company-logo"
                                            style={{borderRadius: "50px"}}
                                            src={require("../images/companies/HPS_logo.jpg")}
                                        />
                                    </div>
                                    <div className={`slide-dscrp-title-container en 
                                        ${(innerWidth <= 500) ? "pl-0 text-center" : (innerWidth > 500 && currentLanguage === 'fa') ? "text-right pr-3" : "text-left pl-3"}
                                       `}>
                                        <p className="slide-dscrp-header pt-4 ">
                                            {sliderData.s1.title}
                                        </p>
                                        <p className="slide-dscrp-subheader ">
                                            {sliderData.s1.sub_title}
                                        </p>
                                    </div>
                                </div>

                                <div
                                    style={currentLanguage === 'fa' ? {direction: "rtl"} : {direction: "ltr"}}
                                    className={`col-12 p-0 slide-dscrp`}
                                >
                                    <p>
                                        {sliderData.s1.description_summary}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*Mobydick*/}
                    <div className="carousel-item  " data-interval="3000">
                        <LazyLoadImage
                            alt="..."
                            className="slide"
                            src={require("../images/companies/c1.webp")}
                            effect="blur"
                            wrapperProps={{
                                style: {transitionDelay: "1s"},
                            }}
                        />

                        <div
                            data-toggle="modal"
                            data-target="#moreInformationModal"
                            onClick={() => {
                                setActiveInformation('slider')
                                setSliderId('s2')
                            }}
                            className="slide-dscrp-container overflow-hidden"
                        >
                            <div className="row h-100">

                                <div
                                    className={`d-flex p-0 ${innerWidth <= 500 ? "flex-column justify-content-center" : (innerWidth > 500 && currentLanguage === 'fa') ? "align-items-center justify-content-end" : "align-items-center justify-content-start"}`}>
                                    <div
                                        className={`logo ${(currentLanguage === 'fa' && innerWidth > 500) ? "order-1" : ""}`}>
                                        <img
                                            className="company-logo"
                                            style={{borderRadius: "50px"}}
                                            src={require("../images/companies/mobydick_logo.png")}
                                        />
                                    </div>
                                    <div className={`slide-dscrp-title-container en 
                                        ${(innerWidth <= 500) ? "pl-0 text-center" : (innerWidth > 500 && currentLanguage === 'fa') ? "text-right pr-3" : "text-left pl-3"}
                                       `}>
                                        <p className="slide-dscrp-header pt-4 ">
                                            {sliderData.s2.title}
                                        </p>
                                        <p className="slide-dscrp-subheader ">
                                            {sliderData.s2.sub_title}
                                        </p>
                                    </div>
                                </div>

                                <div
                                    style={currentLanguage === 'fa' ? {direction: "rtl"} : {direction: "ltr"}}
                                    className={`col-12 p-0 slide-dscrp`}
                                >
                                    {sliderData.s2.description_summary}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*Diamond Citrus*/}
                    <div className="carousel-item  " data-interval="3000">
                        <LazyLoadImage
                            alt="..."
                            className="slide"
                            src={require("../images/companies/c1.webp")}
                            effect="blur"
                            wrapperProps={{
                                style: {transitionDelay: "1s"},
                            }}
                        />

                        <div
                            data-toggle="modal"
                            data-target="#moreInformationModal"
                            onClick={() => {
                                setActiveInformation('slider')
                                setSliderId('s3')
                            }}
                            className="slide-dscrp-container overflow-hidden"
                        >
                            <div className="row h-100">

                                <div
                                    className={`d-flex p-0 ${innerWidth <= 500 ? "flex-column justify-content-center" : (innerWidth > 500 && currentLanguage === 'fa') ? "align-items-center justify-content-end" : "align-items-center justify-content-start"}`}>
                                    <div
                                        className={`logo ${(currentLanguage === 'fa' && innerWidth > 500) ? "order-1" : ""}`}>
                                        <img
                                            className="company-logo"
                                            style={{borderRadius: "50px"}}
                                            src={require("../images/companies/Diamond_Citrus_logo.jpg")}
                                        />
                                    </div>
                                    <div className={`slide-dscrp-title-container en 
                                        ${(innerWidth <= 500) ? "pl-0 text-center" : (innerWidth > 500 && currentLanguage === 'fa') ? "text-right pr-3" : "text-left pl-3"}
                                       `}>
                                        <p className="slide-dscrp-header pt-4 ">
                                            {sliderData.s3.title}
                                        </p>
                                        <p className="slide-dscrp-subheader ">
                                            {sliderData.s3.sub_title}
                                        </p>
                                    </div>
                                </div>

                                <div
                                    style={currentLanguage === 'fa' ? {direction: "rtl"} : {direction: "ltr"}}
                                    className={`col-12 p-0 slide-dscrp`}
                                >
                                    {sliderData.s3.description_summary}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*Enekas*/}
                    <div className="carousel-item  " data-interval="3000">
                        <LazyLoadImage
                            alt="..."
                            className="slide"
                            src={require("../images/companies/c1.webp")}
                            effect="blur"
                            wrapperProps={{
                                style: {transitionDelay: "1s"},
                            }}
                        />

                        <div
                            data-toggle="modal"
                            data-target="#moreInformationModal"
                            onClick={() => {
                                setActiveInformation('slider')
                                setSliderId('s4')
                            }}
                            className="slide-dscrp-container overflow-hidden"
                        >
                            <div className="row h-100">

                                <div
                                    className={`d-flex p-0 ${innerWidth <= 500 ? "flex-column justify-content-center" : (innerWidth > 500 && currentLanguage === 'fa') ? "align-items-center justify-content-end" : "align-items-center justify-content-start"}`}>
                                    <div
                                        className={`logo ${(currentLanguage === 'fa' && innerWidth > 500) ? "order-1" : ""}`}>
                                        <img
                                            className="company-logo"
                                            style={{borderRadius: "50px"}}
                                            src={require("../images/companies/Enekas_logo.jpg")}
                                        />
                                    </div>
                                    <div className={`slide-dscrp-title-container en 
                                        ${(innerWidth <= 500) ? "pl-0 text-center" : (innerWidth > 500 && currentLanguage === 'fa') ? "text-right pr-3" : "text-left pl-3"}
                                       `}>
                                        <p className="slide-dscrp-header pt-4 ">
                                            {sliderData.s4.title}
                                        </p>
                                        <p className="slide-dscrp-subheader ">
                                            {sliderData.s4.sub_title}
                                        </p>
                                    </div>
                                </div>

                                <div
                                    style={currentLanguage === 'fa' ? {direction: "rtl"} : {direction: "ltr"}}
                                    className={`col-12 p-0 slide-dscrp`}
                                >
                                    {sliderData.s4.description_summary}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*HT*/}
                    <div className="carousel-item  " data-interval="3000">
                        <LazyLoadImage
                            alt="..."
                            className="slide"
                            src={require("../images/companies/c1.webp")}
                            effect="blur"
                            wrapperProps={{
                                style: {transitionDelay: "1s"},
                            }}
                        />

                        <div
                            data-toggle="modal"
                            data-target="#moreInformationModal"
                            onClick={() => {
                                setActiveInformation('slider')
                                setSliderId('s5')
                            }}
                            className="slide-dscrp-container overflow-hidden"
                        >
                            <div className="row h-100">

                                <div
                                    className={`d-flex p-0 ${innerWidth <= 500 ? "flex-column justify-content-center" : (innerWidth > 500 && currentLanguage === 'fa') ? "align-items-center justify-content-end" : "align-items-center justify-content-start"}`}>
                                    <div
                                        className={`logo ${(currentLanguage === 'fa' && innerWidth > 500) ? "order-1" : ""}`}>
                                        <img
                                            className="company-logo"
                                            style={{borderRadius: "50px"}}
                                            src={require("../images/companies/HT_logo.jpg")}
                                        />
                                    </div>
                                    <div className={`slide-dscrp-title-container en 
                                        ${(innerWidth <= 500) ? "pl-0 text-center" : (innerWidth > 500 && currentLanguage === 'fa') ? "text-right pr-3" : "text-left pl-3"}
                                       `}>
                                        <p className="slide-dscrp-header pt-4 ">
                                            {sliderData.s5.title}
                                        </p>
                                        <p className="slide-dscrp-subheader ">
                                            {sliderData.s5.sub_title}
                                        </p>
                                    </div>
                                </div>

                                <div
                                    style={currentLanguage === 'fa' ? {direction: "rtl"} : {direction: "ltr"}}
                                    className={`col-12 p-0 slide-dscrp`}
                                >
                                    {sliderData.s5.description_summary}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*Venus mobile*/}
                    <div className="carousel-item  " data-interval="3000">
                        <LazyLoadImage
                            alt="..."
                            className="slide"
                            src={require("../images/companies/c1.webp")}
                            effect="blur"
                            wrapperProps={{
                                style: {transitionDelay: "1s"},
                            }}
                        />

                        <div
                            data-toggle="modal"
                            data-target="#moreInformationModal"
                            onClick={() => {
                                setActiveInformation('slider')
                                setSliderId('s6')
                            }}
                            className="slide-dscrp-container overflow-hidden"
                        >
                            <div className="row h-100">

                                <div
                                    className={`d-flex p-0 ${innerWidth <= 500 ? "flex-column justify-content-center" : (innerWidth > 500 && currentLanguage === 'fa') ? "align-items-center justify-content-end" : "align-items-center justify-content-start"}`}>
                                    <div
                                        className={`logo ${(currentLanguage === 'fa' && innerWidth > 500) ? "order-1" : ""}`}>
                                        <img
                                            className="company-logo"
                                            style={{borderRadius: "50px"}}
                                            src={require("../images/companies/Venus_mobile_logo.jpg")}
                                        />
                                    </div>
                                    <div className={`slide-dscrp-title-container en 
                                        ${(innerWidth <= 500) ? "pl-0 text-center" : (innerWidth > 500 && currentLanguage === 'fa') ? "text-right pr-3" : "text-left pl-3"}
                                       `}>
                                        <p className="slide-dscrp-header pt-4 ">
                                            {sliderData.s6.title}
                                        </p>
                                        <p className="slide-dscrp-subheader ">
                                            {sliderData.s6.sub_title}
                                        </p>
                                    </div>
                                </div>

                                <div
                                    style={currentLanguage === 'fa' ? {direction: "rtl"} : {direction: "ltr"}}
                                    className={`col-12 p-0 slide-dscrp`}
                                >
                                    {sliderData.s6.description_summary}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <button
                    className="carousel-control-prev btn"
                    type="button"
                    data-target="#carouselExampleIndicators"
                    data-slide="prev"
                >
                      <span className="control-container">
                        <img src={arrow_left} alt="arrow_left"/>
                      </span>
                </button>
                <button
                    className="carousel-control-next btn"
                    type="button"
                    data-target="#carouselExampleIndicators"
                    data-slide="next"
                >
                      <span className="control-container">
                        <img src={arrow_right} alt="arrow right"/>
                      </span>
                </button>
            </div>
        </section>
    );
}
